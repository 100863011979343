<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-block">
        <b-card-title class="mb-1">
          Temsilciler
        </b-card-title>
        <b-card-sub-title class="font-small-2 font-italic">
          Tarih aralığındaki yapılan toplam tahsilatlar
        </b-card-sub-title>
      </div>
      <div class="text-muted font-small-2">
        {{ moment(reportParams.sdate).format('DD.MM.YYYY') }} - {{ moment(reportParams.edate).format('DD.MM.YYYY') }}
      </div>
    </b-card-header>
    <pre-loading
      v-if="loading"
      loading-text="Yükleniyor..."
      color="text-secondary bg-light-primary mx-2 mb-2 rounded p-2"
    />
    <b-table
      v-else
      responsive="sm"
      class="mb-0"
      :fields="fields"
      :items="reportData.dataList.userPayments"
      empty-text="Kayıt bulunamadı."
      show-empty
      bordered
      hover
      striped
    >
      <template #cell(username)="data">
        <div class="font-weight-bold text-secondary">
          {{ data.item.username }}
        </div>
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount | toCurrency }} ₺
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BCardSubTitle, BCardTitle, BCardHeader, BTable,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'UserPayments',
  components: {
    PreLoading,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCard,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: 'TEMSİLCİ',
          thClass: 'text-nowrap',
        },
        {
          key: 'amount',
          label: 'TOPLAM TAHSİLAT',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
      ],
    }
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportCurrentStatus/reportParams']
    },
    loading() {
      return this.$store.getters['reportCurrentStatus/loading']
    },
    reportData() {
      return this.$store.getters['reportCurrentStatus/reportData']
    },
  },
}
</script>
