<template>
  <div>
    <report-params :get-report="getData" />
    <counters />
    <b-row>
      <b-col>
        <latest-payments />
        <late-payments />
        <pending-payments />
      </b-col>
      <b-col>
        <apex-donut-chart
          v-if="reportData.charts.userPayments.labels.length"
          :loading="loading"
          chart-title="Temsilciler"
          chart-sub-title="Tarih aralığındaki yapılan tahsilatların temsilci oranı"
          :chart-prefix-text="moment(reportParams.sdate).format('DD.MM.YYYY') + ' - ' + moment(reportParams.edate).format('DD.MM.YYYY')"
          :categories-data="reportData.charts.userPayments.labels"
          :series-data="reportData.charts.userPayments.series"
          :colors-data="reportData.charts.userPayments.colors"
          :height-size="650"
        />
        <user-payments />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ReportParams from '@/views/Reports/CurrentStatus/components/ReportParams.vue'
import Counters from '@/views/Reports/CurrentStatus/components/Counters.vue'
import UserPayments from '@/views/Reports/CurrentStatus/components/UserPayments.vue'
import LatestPayments from '@/views/Reports/CurrentStatus/components/LatestPayments.vue'
import LatePayments from '@/views/Reports/CurrentStatus/components/LatePayments.vue'
import PendingPayments from '@/views/Reports/CurrentStatus/components/PendingPayments.vue'
import ApexDonutChart from '@/views/Reports/components/ApexDonutChart.vue'

export default {
  name: 'CurrentStatusIndex',
  components: {
    BRow,
    BCol,
    ReportParams,
    Counters,
    UserPayments,
    LatestPayments,
    LatePayments,
    PendingPayments,
    ApexDonutChart,
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportCurrentStatus/reportParams']
    },
    loading() {
      return this.$store.getters['reportCurrentStatus/loading']
    },
    reportData() {
      return this.$store.getters['reportCurrentStatus/reportData']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('reportCurrentStatus/getData', this.reportParams)
    },
  },
}
</script>
